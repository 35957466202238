<template lang="html">
  <div class="contractSign" v-if="detail">
    <div class="box">
      <div class="title">
        商铺租凭合同
      </div>
      <div class="head">
        <div class="list">
          <span>甲方：</span>
          <div class="content">
            {{ propertyUserInfo.marketName }}
          </div>
        </div>
        <div class="list">
          <span>乙方：</span>
          <div class="content">
            {{ detail.contract.contract_name }}
          </div>
        </div>
      </div>
      <div class="boxText" v-html="detail.contract.contract_content">
      </div>
      <div class="base">
        <div class="left">
          <div class="list">
            <span>甲方：</span>
            <div class="content"></div>
          </div>
          <div class="list">
            <span>日期：</span>
            <div class="content"></div>
          </div>
        </div>
        <div class="right">
          <div class="list">
            <span>乙方：</span>
            <div class="content" @click="sign">
              <span class="sign">点击签字</span>
            </div>
          </div>
          <div class="list">
            <span>日期：</span>
            <div class="content"></div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model:show="show" round :closeable="true">
      <div class="modelBox">
        <div class="title">
          <span>请在空白处进行签字</span>
        </div>
        <div class="drawArea" ref="drawAreaBox">
          <canvas class="canvasBox" id="myCanvas" ref="drawArea" @touchstart="mStart" @touchmove="mMove"
            @touchend="mEnd"></canvas>
        </div>
        <div class="base">
          <div class="btn">
            <van-button color="#85C226" plain round @click="clearcanvas">重写</van-button>
          </div>
          <div class="btn">
            <van-button type="primary" round @click="confirm">确定</van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'contractSign',
  data () {
    return {
      show: false,
      stageInfo: null,
      ctx: null,
      moving: false,
      point: {
        x: 0,
        y: 0,
      },
      id: '',
      detail: '',
      propertyUserInfo: ''
    };
  },
  created () {
    this.id = this.$route.query.id;
    this.getDetail();
    let market = window.localStorage.getItem('propertyUserInfo') || '';
    if (market) {
      this.propertyUserInfo = JSON.parse(market);
    }
  },
  methods: {
    getDetail () {
      this.$require.HttpPost('/h5/contract_stall/contract', { contract_id: this.id }).then(res => {
        this.detail = res.data;
      });
    },
    mStart (e) {
      let x = e.touches[0].clientX - this.stageInfo.left;
      let y = e.touches[0].clientY - this.stageInfo.top; // 获取触摸点在画板（canvas）的坐标
      this.point.x = x;
      this.point.y = y;
      this.ctx.beginPath();
      this.moving = true;
    },
    mMove (e) {
      if (this.moving) {
        let x = e.touches[0].clientX - this.stageInfo.left;
        let y = e.touches[0].clientY - this.stageInfo.top; // 获取触摸点在画板（canvas）的坐标
        this.ctx.moveTo(this.point.x, this.point.y); // 把路径移动到画布中的指定点，不创建线条(起始点)
        this.ctx.lineTo(x, y); // 添加一个新点，然后创建从该点到画布中最后指定点的线条，不创建线条
        this.ctx.stroke(); // 绘制
        this.point.x = x;
        this.point.y = y; // 重置点坐标为上一个坐标
      }
    },
    mEnd () {
      if (this.moving) {
        this.ctx.closePath(); // 停止绘制
        this.moving = false; // 关闭绘制开关
      }
    },
    sign () {
      this.show = true;
      this.$nextTick(() => {
        let drawArea = document.getElementById('myCanvas'); // 获取DOM
        this.stageInfo = drawArea.getBoundingClientRect();
        drawArea.width = this.$refs.drawAreaBox.offsetWidth; // 设置画布宽
        drawArea.height = this.$refs.drawAreaBox.offsetHeight; // 设置画布高
        this.ctx = drawArea.getContext('2d'); // 二维绘图
        this.ctx.strokeStyle = 'black'; // 颜色
        this.ctx.lineWidth = '3'; // 线条宽度
      });
    },
    confirm () {
      let img = document.getElementById('myCanvas').toDataURL();
      this.$require.HttpPost('/h5/contract/writeName', { contract_id: this.id, contract_address: img }).then(() => {
        this.$toast('签名成功');
        setTimeout(() => {
          this.$router.replace({ path: '/my' });
        }, 500);
      });
      // document.getElementById("myCanvas").toDataURL("image/png");
      // document.getElementById("myCanvas").toBlob(async (blobObj) => {
      //   var file1 = new File([blobObj], "sign.png", {
      //     type: blobObj.type,
      //     lastModified: Date.now(),
      //   });
      //   let formData = new FormData();
      //   formData.append('contract_address', file1);
      //   formData.append('contract_id', this.id);
      //   this.$require.HttpPost('/h5/contract/writeName', formData).then(res => {
      //     // debugger;
      //   });
      // });
    },
    clearcanvas () {
      let c = document.getElementById('myCanvas');
      let cxt = c.getContext('2d');
      // c.height = c.height;
      this.ctx.strokeStyle = 'black'; // 颜色
      this.ctx.lineWidth = 3;
    },
  }
};
</script>

<style lang="scss" scoped>
.contractSign {
  background: #B9F55C;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 30px 24px 40px 24px;
  overflow: hidden;

  .box {
    background: #FFFFFF;
    height: 100%;
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    padding: 0 20px;
    position: relative;
    overflow: hidden;

    .title {
      padding: 30px 0;
      text-align: center;
      font-size: 32px;
      color: #000000;
    }

    .head {
      .list {
        display: flex;
        font-size: 28px;
        color: #333333;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .boxText {
      overflow-y: auto;
      margin-top: 40px;
      font-size: 28px;
      color: #010000;
      height: calc(100vh - 450px);
    }

    .base {
      height: 140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 0px;
      width: calc(100% - 40px);
      bottom: 0;
      font-size: 28px;
      padding: 0 20px;
      color: #333333;

      .list {
        display: flex;
        margin-bottom: 20px;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }

        .sign {
          color: #529100;
        }
      }
    }
  }

  .modelBox {
    width: 702px;
    height: 625px;
    background: #FFFFFF;
    border-radius: 30px;

    .title {
      height: 119px;
      text-align: center;
      line-height: 119px;
      position: relative;
      font-size: 32px;
      color: #000000;

      img {
        position: absolute;
        width: 60px;
        height: 60px;
        right: 11px;
        top: 11px;
      }
    }

    .drawArea {
      width: 662px;
      height: 300px;
      background: #F3F9E9;
      border-radius: 15px;
      margin: 0 auto;

      .canvasBox {
        width: 662px;
        height: 300px;
      }
    }

    .base {
      display: flex;
      justify-content: center;
      margin-top: 60px;

      .btn {
        width: 250px;
        height: 96px;

        &:last-child {
          margin-left: 60px;
        }
      }
    }
  }
}
</style>
